"use client";

import React, { createContext, useContext, useState, useEffect, useReducer } from "react";

interface StoreContextValue {
  state: {
    pageType: string;
    formSectionName: string;
    sectionTitle?: string;
    leadStartingCta?: string;
  };
  // dispatch: (newState: Partial<StoreContextValue["state"]>) => void;
}

const StoreContext = createContext<StoreContextValue>({
  state: {
    pageType: "Home Page",
    formSectionName: "",
    sectionTitle: "",
    leadStartingCta: "",
  },
  //dispatch: () => {},
});

export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return context;
};

export default function StoreSession() {
  const pageType =
    typeof window !== "undefined"
      ? document.querySelector('meta[name="page-type"]')?.getAttribute("content") || "Home Page"
      : "Home Page";
  const [state, setState] = useState<StoreContextValue["state"]>({
    pageType: pageType,
    formSectionName: "",
    sectionTitle: "",
    leadStartingCta: "",
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      window.sessionStorage.setItem(
        "globalStore",
        JSON.stringify({
          pageType: pageType,
          formSectionName: "",
          sectionTitle: "",
          leadStartingCta: "",
        })
      );
    }
  }, []);



  return null
}

