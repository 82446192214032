import React from "react";

import styles from "./NationalInternational.module.scss";

export default function NationalInternational({ countryCode = "+91", updateCountryCode = (value) => null  }) {
  const isIndianNumber = countryCode === "+91";

  const updateIndianNumber = ()=>{
    updateCountryCode('+91');
  }

  const updateInternationalNumber = () => {
    updateCountryCode('Code');
  }

  return (
    <div className={styles.container}>
      <button
        onClick={updateIndianNumber}
        className={
          isIndianNumber ? styles.national: `${styles.national} ${styles.notSelectedButton}`
        }
      >
        Indian
      </button>
      <button
        onClick={updateInternationalNumber}
        className={
          !isIndianNumber
            ? `${styles.interNational}`
            : `${styles.interNational} ${styles.notSelectedButton}`
        }
      >
        International
      </button>
    </div>
  );
}
