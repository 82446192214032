import style from "./Input.module.scss";

interface InputProps {
  placeholder: string;
  value: string;
  onChangeFunc: (value: string) => void;
  disabled?: boolean;
  onBlur?: () => void;
}

export default function Input({
  placeholder,
  value,
  onChangeFunc,
  disabled = false,
  onBlur,
}: InputProps) {
  return (
    <input
      type="text"
      placeholder={placeholder}
      className={`text-stone-500 border border-neutral-200 outline-none focus:border-primary-blue w-full xl:w-full ${style.Input__FieldFont}`}
      value={value}
      onChange={(e) => onChangeFunc(e.target.value)}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
}
