import strapiSingleAttribute from "@/api/strapiSingleAttribute";
import styles from "./styles.module.scss";
import Markdown from "@/components/atoms/Markdown";

export default function MediaFormSection({
  Heading,
  Image,
  isMobile,
  children,
}) {
  const img = strapiSingleAttribute(
    isMobile && Image?.mobileSource ? Image?.mobileSource : Image?.source
  );

  return (
    <div className={styles.root}>
      {Image?.enabled ? (
        <img src={img?.url} alt={Image?.alt} className={styles.image} />
      ) : null}
      <div>
        <Markdown className={styles.title}>{Heading}</Markdown>
        {children}
      </div>
    </div>
  );
}
