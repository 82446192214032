interface DataLayer {
  push: (items: any[]) => number;
}

declare global {
  interface Window {
    dataLayer: DataLayer;
  }
}

export const pushToDataLayer = (data: any) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};