export class StorageService {

    static _getStorage(storageType: string = "session") {
      if (typeof window === "undefined") {
        throw new Error("Window is not defined")
      } else if (storageType === "local") {
        return window.localStorage
      } else if (storageType === "session") {
        return window.sessionStorage
      } else {
        throw new Error("Invalid storage type")
      }
    }
    static set(key: string, value: any, storageType = "session") {
      try {
        const storage = StorageService._getStorage(storageType)
        storage.setItem(key, JSON.stringify(value))
      } catch (error) {
        console.error(error)
      }
    }
    static get(key: string, storageType: string = "session"): any {
      try {
        const storage = StorageService._getStorage(storageType)
        const data = storage.getItem(key)
        return JSON.parse(data) || null
      } catch (e) {
        return null
      }
    }
    static clearItem(key: string, storageType: string = "session") {
      try {
        const storage = StorageService._getStorage(storageType)
        storage.removeItem(key)
      } catch (error) {
        console.error(error)
      }
    }
    static clearAll(storageType: string = "session") {
      try {
        const storage = StorageService._getStorage(storageType)
        storage.clear()
      } catch (error) {
        console.error(error)
      }
    }
  }
  