import React from "react";
import cn from "clsx";

export default function Checkbox({
  value,
  onChangeFunc,
  name,
  onBlur
}: {
  name?: string;
  value: boolean;
  onChangeFunc: (value: boolean) => void;
  onBlur? : () => void;
}) {
  return (
    <input
      type="checkbox"
      checked={value}
      name={name}
      onChange={() => onChangeFunc(!value)}
      onBlur={onBlur}
      className={cn(
        "outline-none text-primary-blue rounded-[50%] align-middle border-[1px] border-stone-500 appearance-none cursor-pointer checked:bg-primary-blue checked:border-transparent checked:outline-none checked:ring-2 checked:ring-primary-blue checked:ring-offset-2 checked:ring-offset-white",
        value &&
          "bg-primary-blue border-transparent outline-none ring-2 ring-primary-blue ring-offset-2 ring-offset-white"
      )}
    />
  );
}
